import React from "react";
import SEO from "../components/Seo/seo"
import styled from 'styled-components';
import { theme } from '../utils/theme';
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";
import LampComponent from "../components/Home/Lamp/LampComponent";
import "react-multi-carousel/lib/styles.css";
import { StyledMainContainer, StyledLeftContainer, StyledDescription, StyledButtonWrapper } from './head';
import FooterComponentCentered from "../components/Home/Footer/FooterComponentCentered";


// ---- List Styles ----

const StyledMain = styled(StyledMainContainer)`
justify-content: space-between;

${theme.media.desktop} {
padding: 0;
}
`
const StyledRightBuyContainer = styled.div`
padding-right: 10%;

img {
filter: grayscale(0);
}  

${theme.media.cloud} {
display: none
}
`
const StyledBuyDescription = styled(StyledDescription)`
margin-bottom: 100px;

${theme.media.desktop} {
text-align: center;
    }

${theme.media.mobile} {

p, h2 {
    text-align: center;
    margin: 50px 0;
    }
}

h2 {
    border-bottom: 1px solid #c8c7e2;
    display: inline-block;
}
`
const StyledDescriptionButtonWrapper = styled(StyledButtonWrapper)`
justify-content: space-between;

a {
    ${theme.buttonMain}
    margin-right: 0;
}

div:first-child {
    margin-right: 150px;

    ${theme.media.desktop} {
        margin: 30px;
    }
}

${theme.media.desktop} {
    ${theme.flex.centeredColumn};
    height: 100%;
    }

${theme.media.mobile} {
text-align: center;
margin-bottom: 100px;
}
`


const ProceedSubpage = () => {

    return (
        <Layout>
            <SEO title="Proceed" />

            <StyledMain>

                <StyledLeftContainer>

                    <StyledBuyDescription>
                        <h2 itemScope>How to Buy</h2>


                        <StyledDescriptionButtonWrapper>
                            <div> <a itemProp="url" href="https://www.etsy.com/uk/listing/868895993/floor-lamp-standing-lamp" target="blank" aria-label="etsy">Etsy</a></div>
                        </StyledDescriptionButtonWrapper>

                    </StyledBuyDescription>

                    <StyledButtonWrapper>
                        <Link title="This is a link to buy subpage" as="a" to="/buy" aria-label="buy"><h1>Back</h1></Link>
                        <Link title="This is a link to home page" as="a" to="/" aria-label="home"><h1>Home</h1></Link>
                    </StyledButtonWrapper>


                </StyledLeftContainer>

                <StyledRightBuyContainer>

                    < LampComponent />

                </StyledRightBuyContainer>

                <FooterComponentCentered />

            </StyledMain>

        </Layout>
    )
}

export default ProceedSubpage;